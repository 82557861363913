.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.themeInput, .themeButton, .episodeScript {
  margin: 20px;
}

.infinity, .thperiod {
  height: 70px; /* Adjust this value to make the SVGs larger or smaller */
  width: auto;
  margin: 10px;
  padding-top: 20px;
}

@keyframes rotate-infinity {
  from {
    transform: rotate(-90deg);
    margin-top: 50px;
    margin-right: -15px;
  }
  to {
    transform: rotate(0deg);
    margin-top: 10px;
    margin-right: 0px;
  }
}

.infinity {
  transform: rotate(-90deg);
  margin-top: 50px;
  margin-right: -15px;
  animation: rotate-infinity 1s ease-out forwards;
  animation-delay: 1s;
}

.logo-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  max-width: 500px; /* This should match the max-width of your themeInput */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.explanation {
  font-style: italic;
  font-weight: 300;
  color: #333;
  text-align: center;
  margin-bottom: 10px;
  opacity: 0;
  animation: fadeIn 1s ease-in forwards;
  animation-delay: 2s; /* This should match the duration of the rotate-infinity animation plus */
}

.episodeScript {
  text-align: center;
  max-width: 70%;
  margin: 10 auto;
  font-family: 'Georgia', serif;
  line-height: 1.6;
  color: #333;
  padding: 10px;
  border-radius: 5px;
  background-color: #f2f2f2;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
}

.themeInput {
  width: 80%;
  max-width: 400px;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  box-sizing: border-box;
  outline: none;
  transition: border-color 0.3s ease;
}

.themeInput:focus {
  border-color: #007BFF;
}

.themeButton {
  width: 40%;
  max-width: 200px;
  height: 50px;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  background-color: #cf4646;
  color: white;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.copyButton {
  width: 20%;
  max-width: 100px;
  height: 50px;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  background-color: #f6d160;
  color: white;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.copyButton:hover {
  background-color: #d4b14a;
}

.themeButton:hover {
  background-color: #aa1219;
}

.themeButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.funnel {
  animation: spin 2s linear infinite;
  height: 120px;
  padding: 30px;
}
